import React from 'react';
import { Helmet } from 'react-helmet';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import Casino from 'sections/ScreverOcxProgram/Casino';
import FooterCommon from 'sections/ScreverOcxProgram/FooterCommon';

const PAGES = [
  {
    title: 'Program',
    url: '/events/screver-ocx-program/program',
  },
  {
    title: 'Participants',
    url: '/events/screver-ocx-program/participants',
  },
  {
    title: '✌🏻Feedback',
    url: '/events/screver-ocx-program/feedback',
  },
];

const CasinoInstructions = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Screver OCX Event | Casino instructions"
      >
        <meta
          name="description"
          content="With Screver, feedback is not left to chance - Winning at the gaming table is!"
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="scr-ocx-program">
        <HeaderCommon title="Casino instructions" />

        <main>
          <div className="ocx-prog-wrap">
            <Casino />
          </div>
        </main>

        <FooterCommon linksToPages={PAGES} />
      </div>
    </>
  );
};

export default CasinoInstructions;
